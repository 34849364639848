import {ROUTES} from '@/config'
import LOCALE from '@/config/locale.js'
import {createRouter, createWebHistory} from 'vue-router'
import {generateRoutes} from '@/extensions/langRouter'

const Organizations = () => import('@/views/Organization/OrganizationIndex.vue')

const routes = [
    {
        path: '',
        paths: {en: ''},
        name: ROUTES.ORGANIZATION.INDEX,
        component: Organizations
    },
    {
        path: '/organisations/:id/:slug?',
        paths: {en: '/organizations/:id/:slug?'},
        name: ROUTES.ORGANIZATION.SHOW,
        component: () => import('@/views/Organization/OrganizationShow.vue'),
        props: true
    },
    {
        path: '/mon-organisation',
        paths: {en: '/my-organization'},
        name: ROUTES.AUTH.BASE,
        component: () => import('@/views/Auth/Auth.vue'),
        children: [
            {
                path: '',
                paths: {en: ''},
                name: ROUTES.ORGANIZATION.EDIT,
                component: () => import('@/views/Organization/OrganizationEdit.vue')
            }
        ]
    },
    {
        path: '/se-connecter',
        paths: {en: '/login'},
        name: ROUTES.AUTH.LOGIN,
        component: () => import('@/views/Auth/AuthLogin.vue')
    },
    {
        path: '/politique-de-confidentialite',
        paths: {en: '/privacy-policy'},
        name: ROUTES.PRIVACY,
        component: () => import('@/views/Privacy.vue')
    },
    {
        path: '/migration-vitrine',
        paths: {en: '/vitrine-migration'},
        name: ROUTES.VITRINE,
        component: () => import('@/views/Vitrine.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'catch-all',
        component: Organizations
    }
]

const localizedRoutes = generateRoutes(routes, LOCALE.DEFAULT, 'en')

export default createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    linkActiveClass: '-active',
    linkExactActiveClass: '-exact-active',
    routes: localizedRoutes,
    scrollBehavior(to) {
        return to.hash
            ? {el: to.hash, behavior: 'smooth', top: 20}
            : {top: 0, behavior: 'smooth'}
    }
})