export default {
    ai: {
        'title': 'What are the AI capabilities of the organization?'
    },
    competences: {
        'title': 'Is the categorization of the organization appropriate?'
    },
    contact: {
        title: 'Are the collected contact details accurate?'
    },
    expertises: {
        title: 'Are the specific areas of expertise well defined?'
    },
    intro: {
        description: 'Become an essential resource for companies looking to develop or adopt AI. An easy and guided questionnaire will help you create an effective profile to attract the right partners. Maximize your potential and connect with organizations ready to innovate with your solutions today!',
        hint: 'Takes about 10 minutes to complete',
        title: 'Increase your organization\'s <em>visibility</em> with Miria'
    },
    knowledge: {
        title: 'Are the areas of expertise well defined?'
    },
    links: {
        description: 'You can include in the organization\'s profile links to news, case studies, projects, collaborations and anything that can show its successes to potential partners who visit Miria.',
        title: 'Would you like to add any useful links?'
    },
    logo: {
        description: 'We recommend uploading the horizontal version of your organization\'s logo in SVG, PNG or JPG format. Get a preview of how it will appear in the Miria directory below.',
        title: 'What is the organization\'s logo?'
    },
    medias: {
        description: 'You can include photos and videos in your profile that represent your service offering and/or product. This is the time to showcase the organization and present it more concretely to potential partners visiting Miria.',
        subtitle: 'Relevant media selected from the organization’s website',
        title: 'Would you like to add photos and videos to your profile?'
    },
    offer_description: {
        'title': 'Is the organization\'s offer well-defined?'
    },
    partnership_benefits: {
        title: 'Is the benefit of a partnership well defined?'
    },
    outro: {
        contact: 'Do you have any suggestions for improving the registration process? The platform is evolving and your feedback is valuable. Do not hesitate to contact us to share your opinion!',
        description: 'You have successfully created your profile.',
        title: 'Mission accomplished!'
    },
    preview: {
        description: 'Here is a preview of the organization sheet that will be displayed on Miria, created with the information you have entered. If you want to modify any sections, click on the appropriate information below or go back to the previous pages.'
    },
    quotes: {
        0: 'Quebec ranks first in the world for the most cited AI publications.',
        1: 'More than 500 companies are involved in the field of AI in Quebec.',
        2: 'There are over 250 specialized AI support entities in Quebec that assist local entrepreneurs.',
        3: 'Quebec ranks first in the number of AI engineers per million workers.',
        4: 'For several years, the governments of Quebec and Canada have invested nearly 23 million dollars in research hubs and specialized institutes for the study of responsible AI.'
    },
    scraping_preview: {
        description: 'Here is an overview of the information collected on your website. Proceed to the next step to start validating the data.'
    },
    sections: {
        adds: 'Additions',
        analysis: 'Analysis',
        confirm: 'Confirmation',
        contact: 'Contact Information',
        details: 'Details',
        offer: 'Offer'
    },
    socials: {
        title: 'Are the social media links correct?'
    },
    type: {
        title: 'Is the type of organization appropriate?'
    },
    url: {
        'description': 'Please provide the URL of your site so that Miria can analyze it and help you build your profile. The process may take a few minutes. You will then be able to validate the information, approve the content generated by Miria, and modify the details.',
        'title': 'What is the organization\'s main website?'
    }
}