import {defineStore} from 'pinia'
import {MODAL_TYPES} from '@/config'

export const useModalStore = defineStore('modal', {
    state: () => {
        return {
            data: null,
            type: null
        }
    },
    actions: {
        close() {
            this.type = null
            this.data = null
        },
        openCompetences(competences) {
            this.type = MODAL_TYPES.COMPETENCES
            this.data = competences
        },
        openImageImport() {
            this.type = MODAL_TYPES.IMAGE_IMPORT
        },
        openMediaCaptionUpdate(mediaIndex) {
            this.type = MODAL_TYPES.MEDIA_CAPTION_UPDATE
            this.data = mediaIndex
        },
        openMediaShow(media) {
            this.type = MODAL_TYPES.MEDIA_SHOW
            this.data = media
        },
        openOrganizationSearchFeedback() {
          this.type = MODAL_TYPES.ORGANIZATION_SEARCH_FEEDBACK_MODAL
        },
        openYoutubeVideoImport() {
            this.type = MODAL_TYPES.YOUTUBE_VIDEO_IMPORT
        }
    }
})