import {defineStore} from 'pinia'
import {baseApi} from '@/extensions/http/index.js'
import {API_ENDPOINTS} from '@/config'

export const useVitrineStore = defineStore('vitrine', {
    state: () => {
        return {
            organizations: null,
            stats: {
                count_by_date: {},
                migrated: 0,
                total: 0
            },
            unlocked: false
        }
    },
    actions: {
        async init() {
            const response = await baseApi.get(API_ENDPOINTS.ORGANIZATION.VITRINE)

            this.organizations = response.data.organizations
            this.stats = response.data.stats
        },
        async unlock(password) {
            if (password === import.meta.env.VITE_APP_VITRINE_PASSWORD) {
                this.unlocked = true

                await this.init()
            }
        }
    }
})
