<template>
  <Transition name="fade">
    <aside v-if="$modal.type" @click.stop="$modal.close"
           class="fixed top-0 left-0 z-50 w-full h-full bg-modal backdrop-blur-xl md:p-10 flex items-center justify-center cursor-pointer">
      <div
          :class="['bg-deep-indigo-black shadow-organization w-full md:w-[70dvw] md:max-h-[95dvh] cursor-default md:rounded-lg relative', {'h-full': heightMax}]"
          @click.stop>
        <button class="social absolute top-5 right-5 bg-deep-indigo-heavy" type="button"
                @click.stop.prevent="$modal.close">
          <Icon id="close" class="text-xs"/>
        </button>
        <component :is="modal" class="p-10"/>
      </div>
    </aside>
  </Transition>
</template>

<script>
import {Icon} from '@/components/helpers'
import {
  CompetencesModal,
  ImageImportModal,
  MediaCaptionUpdateModal,
  MediaShowModal,
  OrganizationSearchFeedbackModal,
  YoutubeVideoImportModal
} from '@/components/modal/blocks'

export default {
  name: 'Modal',
  components: {Icon},
  computed: {
    heightMax() {
      return this.$modal.type !== this.$modal_types.ORGANIZATION_SEARCH_FEEDBACK_MODAL
    },
    modal() {
      switch (this.$modal.type) {
        case this.$modal_types.COMPETENCES:
          return CompetencesModal
        case this.$modal_types.IMAGE_IMPORT:
          return ImageImportModal
        case this.$modal_types.MEDIA_CAPTION_UPDATE:
          return MediaCaptionUpdateModal
        case this.$modal_types.MEDIA_SHOW:
          return MediaShowModal
        case this.$modal_types.ORGANIZATION_SEARCH_FEEDBACK_MODAL:
          return OrganizationSearchFeedbackModal
        case this.$modal_types.YOUTUBE_VIDEO_IMPORT:
          return YoutubeVideoImportModal
        default:
          return null
      }
    }
  }
}
</script>