export default {
    BIRTHDATE: /^\d{4}\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])$/,
    EMAIL: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,8}$/,
    INSTRUCTIONS: /^[^÷/=@#$ˆ{}|~<>[\]]{2,255}$/,
    MESSAGE: /^[^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,255}$/,
    NAME: /^[^_!¡?÷?¿/\\+=@#$%ˆ&*{}|~<>;:[\]]{2,255}$/,
    NUMBER: /^\d{1,4}$/,
    PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,255}$/,
    PHONE: /^\d{10}$/,
    SIX_DIGITS: /^\d{6}$/,
    STRING_WITH_NUMBERS: /^[^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,255}$/,
    TEAM: {
        NAME: /^[^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,45}$/,
        SHORT_DESCRIPTION: /^[^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,75}$/
    },
    TEXT: /^[^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,1000}$/,
    URL: /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
    YEAR: /^20\d{2}$/,
    YOUTUBE: /^((?:https:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
}