export default {
    AUTH: {
        CONFIRM: '/confirm',
        LOGIN: '/login',
        LOGOUT: '/logout',
        SANCTUM_CSRF: '/sanctum/csrf-cookie'
    },
    DATA: '/api/data',
    ORGANIZATION: {
        INDEX: '/api/organizations',
        MEDIA: {
            CAPTION: '/api/medias/:id/caption',
            DELETE: '/api/medias/:id/',
            LOGO: '/api/organizations/:id/logo',
            STORE: '/api/organizations/:id/medias'
        },
        SHOW: '/api/organizations/:id',
        STORE: '/api/organizations',
        UPDATE: '/api/organizations/:id',
        URLS: '/api/organizations/urls',
        VITRINE: '/api/organizations/vitrine'
    },
    SEARCH: {
        COMMENT: {
            STORE: '/api/searches/:search/comments'
        },
        SHOW: '/api/searches/:id',
        STORE: '/api/searches',
        UPDATE: '/api/searches/:id'
    },
    USER: {
        ME: '/api/me'
    }
}