export default {
    AUTH: {
        BASE: 'auth.base',
        LOGIN: 'auth.login'
    },
    ORGANIZATION: {
        EDIT: 'onboarding.edit',
        INDEX: 'onboarding.index',
        SHOW: 'onboarding.show'
    },
    PRIVACY: 'privacy',
    VITRINE: 'vitrine'
}